.button-link {
    background: none !important;
    border: none;
    padding: 0 !important;
    font-family: arial, sans-serif;
    color: #2c7be5;
    text-decoration: none;
    cursor: pointer;
}

.button-link:hover {
    text-decoration: underline;
    transition: 0.5s ease;
}
